import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As a follow up to their `}<a parentName="p" {...{
        "href": "http://blog.corevc.com/underbanked-hot-hows-a-cool-89billion/"
      }}>{`2012 market sizing report`}</a>{`, CFSI and Core Innovation Capital published this list of, in their words, “principal and up-and-coming movers and shakers in payments for the underbanked, or the Emerging Middle Class.” We found this map so helpful, we printed it and posted it on our physical wall!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1224px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdbxaEQY3//EABwQAAICAgMAAAAAAAAAAAAAAAABAhMDBBEUIf/aAAgBAQABBQKr2pcURFlcTsslsNn/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAwEBPwFT/8QAGREAAgMBAAAAAAAAAAAAAAAAABEBEyFR/9oACAECAQE/AXwm14f/xAAbEAACAgMBAAAAAAAAAAAAAAAAATEyAhARIf/aAAgBAQAGPwKz6e5Ml6gqf//EABoQAQEBAAMBAAAAAAAAAAAAAAERACFRkfH/2gAIAQEAAT8hjlWM0GCGY6g3omfryFj3f//aAAwDAQACAAMAAAAQjy//xAAWEQEBAQAAAAAAAAAAAAAAAAABAFH/2gAIAQMBAT8QF2Nt/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARIXH/2gAIAQIBAT8QGGBFJWu3/8QAHRABAQADAAIDAAAAAAAAAAAAAREAIVExYcHR4f/aAAgBAQABPxC909nUkOHXFeB4xPrpjIDUG6PjJwYkDnh6MqWPN1+MGgdBtOf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/7f61c/Cores-Map-of-Payments.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/d00b9/Cores-Map-of-Payments.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/caef1/Cores-Map-of-Payments.webp 1224w"],
            "sizes": "(max-width: 1224px) 100vw, 1224px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/066f9/Cores-Map-of-Payments.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/4b190/Cores-Map-of-Payments.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/a8586/Cores-Map-of-Payments.jpg 1224w"],
            "sizes": "(max-width: 1224px) 100vw, 1224px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/6f9b6aef5efe24492764b1308dffe300/a8586/Cores-Map-of-Payments.jpg",
            "alt": "Cores-Map-of-Payments",
            "title": "Cores-Map-of-Payments",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "http://blog.corevc.com/wordpress/wp-content/uploads/2013/12/Cores-Map-of-Payments.jpg"
      }}>{`http://blog.corevc.com/wordpress/wp-content/uploads/2013/12/Cores-Map-of-Payments.jpg`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      